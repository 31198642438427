import { template as template_957aceed444b42e19ca225a64c01751d } from "@ember/template-compiler";
const SidebarSectionMessage = template_957aceed444b42e19ca225a64c01751d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
