import { template as template_f4c6d9bab9244fa5a86eb0f4e23fc399 } from "@ember/template-compiler";
const WelcomeHeader = template_f4c6d9bab9244fa5a86eb0f4e23fc399(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
