import { template as template_2e2eb3b89f324c7095618f474536e88f } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_2e2eb3b89f324c7095618f474536e88f(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
