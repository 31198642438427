import { template as template_8853da6785cc491c92cad89440578419 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8853da6785cc491c92cad89440578419(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
