import { template as template_4f8b0f8f27af4b6c9322e63d0710651c } from "@ember/template-compiler";
const FKControlMenuContainer = template_4f8b0f8f27af4b6c9322e63d0710651c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
