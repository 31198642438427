import { template as template_bb74297bda7f49e2a47f04e01cd421fb } from "@ember/template-compiler";
const FKLabel = template_bb74297bda7f49e2a47f04e01cd421fb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
